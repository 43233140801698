import { useState } from 'react';
import { registerUser } from '../api/auth'; // Import the function to register a user
import { Link } from 'react-router-dom'; // Import Link for navigation between routes

export default function Register() {
  // State to hold form data (name, surname, email, and password)
  const [formData, setFormData] = useState({ name: '', surname: '', email: '', password: '' });
  // State to track successful registration
  const [isRegistered, setIsRegistered] = useState(false);

  // Handle input changes and update the corresponding form data
  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const response = await registerUser(formData); // Call the registerUser function with form data
    if (response.token) {
      localStorage.setItem('token', response.token); // Store the token in local storage
      setIsRegistered(true); // Set registration success to true
      setFormData({ name: '', surname: '', email: '', password: '' }); // Clear the form after successful registration
    } else {
      alert(response.error || 'Registration failed'); // Show an alert if registration fails
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form
        className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg"
        onSubmit={handleSubmit} // Attach the handleSubmit function to form submission
      >
        <h2 className="text-3xl font-bold text-center text-blue-600 mb-6">Register</h2>
        
        {/* Input for first name */}
        <input
          className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          name="name" // Name of the input, used in formData
          type="text" // Type of input
          placeholder="First Name" // Placeholder text
          value={formData.name} // Controlled component, value comes from state
          onChange={handleChange} // Update state on change
        />
        
        {/* Input for last name */}
        <input
          className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          name="surname" // Name of the input, used in formData
          type="text" // Type of input
          placeholder="Last Name" // Placeholder text
          value={formData.surname} // Controlled component, value comes from state
          onChange={handleChange} // Update state on change
        />
        
        {/* Input for email */}
        <input
          className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          name="email" // Name of the input, used in formData
          type="email" // Type of input
          placeholder="Email" // Placeholder text
          value={formData.email} // Controlled component, value comes from state
          onChange={handleChange} // Update state on change
        />
        
        {/* Input for password */}
        <input
          className="w-full p-3 mb-6 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          name="password" // Name of the input, used in formData
          type="password" // Type of input
          placeholder="Password" // Placeholder text
          value={formData.password} // Controlled component, value comes from state
          onChange={handleChange} // Update state on change
        />
        
        {/* Submit button */}
        <button
          className="w-full py-3 text-white bg-blue-500 rounded-lg font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
          type="submit" // Button type set to submit
        >
          Register
        </button>

        {/* Conditional rendering for success message and login link */}
        {isRegistered ? (
          <div className="mt-6 text-center">
            <p className="text-green-500 font-semibold">Account created successfully!</p>
            <Link
              to="/login" // Link to the login page
              className="inline-block mt-2 text-blue-500 font-semibold hover:underline focus:outline-none focus:ring-2 focus:ring-blue-400"
            >
              Click here to login
            </Link>
          </div>
        ) : (
          <div className="mt-6 text-center">
            <p className="text-gray-500">Already have an account?</p>
            <Link
              to="/login" // Link to the login page
              className="inline-block mt-2 text-blue-500 font-semibold hover:underline focus:outline-none focus:ring-2 focus:ring-blue-400"
            >
              Login
            </Link>
          </div>
        )}
      </form>
    </div>
  );
}
