import React from 'react';
import Header from'./Header';

// Sample blog data array
const blogPosts = [
  {
    id: 1,
    title: 'Building a Personal Brand in Tech',
    date: 'October 1, 2024',
    summary: 'Learn how to establish and grow your personal brand in the tech industry.',
    imageUrl: 'https://via.placeholder.com/500',
    link: '/blog/personal-branding',
  },
  {
    id: 2,
    title: 'Mastering React for Modern Web Development',
    date: 'September 10, 2024',
    summary: 'A comprehensive guide to becoming proficient in React for web development.',
    imageUrl: 'https://via.placeholder.com/500',
    link: '/blog/mastering-react',
  },
  {
    id: 3,
    title: 'Introduction to Cloud Computing with AWS',
    date: 'August 22, 2024',
    summary: 'An introductory article on leveraging AWS for cloud solutions.',
    imageUrl: 'https://via.placeholder.com/500',
    link: '/blog/aws-cloud-introduction',
  },
  {
    id: 3,
    title: 'Introduction to Cloud Computing with AWS',
    date: 'August 22, 2024',
    summary: 'An introductory article on leveraging AWS for cloud solutions.',
    imageUrl: 'https://via.placeholder.com/500',
    link: '/blog/aws-cloud-introduction',
  },
  {
    id: 3,
    title: 'Introduction to Cloud Computing with AWS',
    date: 'August 22, 2024',
    summary: 'An introductory article on leveraging AWS for cloud solutions.',
    imageUrl: 'https://via.placeholder.com/500',
    link: '/blog/aws-cloud-introduction',
  },
  {
    id: 3,
    title: 'Introduction to Cloud Computing with AWS',
    date: 'August 22, 2024',
    summary: 'An introductory article on leveraging AWS for cloud solutions.',
    imageUrl: 'https://via.placeholder.com/500',
    link: '/blog/aws-cloud-introduction',
  },
];

const Blog = () => {
  return (
    <div>
      <Header />
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-white to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      {/* Updated Header Section */}
      <h1 className="text-6xl py-8 font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 via-pink-500 to-purple-600 text-center mt-2">
        Welcome to My Blog
      </h1>
      <p className="text-lg text-gray-500 mt-4 text-center w-full max-w-3xl px-4">
        Dive into the latest trends, insights, and articles about technology, personal development, and much more. Whether you’re a seasoned developer or just getting started, there's something for everyone.
      </p>

      {/* Animated Decorative Line */}
      <div className="relative w-24 h-1 bg-gradient-to-r from-indigo-600 via-pink-500 to-purple-600 mt-4 mb-12 animate-pulse"></div>

      {/* Blog Post Cards */}
      <div className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
        {blogPosts.map((post) => (
          <div
            key={post.id}
            className="bg-white border rounded-xl shadow-xl transform transition duration-500 hover:scale-105 hover:shadow-2xl overflow-hidden flex flex-col"
          >
            {/* Blog Post Image */}
            <div className="relative">
              <img
                src={post.imageUrl}
                alt={post.title}
                className="w-full h-64 object-cover rounded-t-xl transition-opacity duration-300 hover:opacity-80"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-500"></div>
              <div className="absolute bottom-4 left-4 text-white text-lg font-semibold">
                {post.date}
              </div>
            </div>

            {/* Blog Post Content */}
            <div className="p-6 flex flex-col flex-grow">
              <h2 className="text-2xl font-bold text-gray-900">{post.title}</h2>
              <p className="mt-4 text-gray-600 flex-grow">{post.summary}</p>

              {/* Read More Button */}
              <a
                href={post.link}
                className="mt-6 text-accent hover:underline text-sm font-medium self-start"
              >
                Read More →
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Blog;
