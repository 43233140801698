// Base URL for the API
const API_URL = 'https://ec2-54-226-179-106.compute-1.amazonaws.com:3000';

/**
 * Registers a new user by sending their data to the API.
 * @param {Object} data - The user data to register, should include properties like email and password.
 * @returns {Promise<Object>} - The response from the API as a JSON object.
 */
export async function registerUser(data) {
  // Make a POST request to the registration endpoint
  const response = await fetch(`${API_URL}/auth/register`, {
    method: 'POST', // Specify the request method
    headers: { 'Content-Type': 'application/json' }, // Set content type to JSON
    body: JSON.stringify(data), // Convert the data object to a JSON string for the request body
  });
  
  // Return the response as a JSON object
  return response.json();
}

/**
 * Logs in a user by sending their credentials to the API.
 * @param {Object} data - The login data, should include properties like email and password.
 * @returns {Promise<Object>} - The response from the API as a JSON object.
 */
export async function loginUser(data) {
  // Make a POST request to the login endpoint
  const response = await fetch(`${API_URL}/auth/login`, {
    method: 'POST', // Specify the request method
    headers: { 'Content-Type': 'application/json' }, // Set content type to JSON
    body: JSON.stringify(data), // Convert the data object to a JSON string for the request body
  });
  
  // Return the response as a JSON object
  return response.json();
}
