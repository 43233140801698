import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Corrected import of jwt-decode

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [healthStatus, setHealthStatus] = useState('Checking...'); // State for health check
  const navigate = useNavigate();

  // Fetch users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
        const decodedToken = jwtDecode(token);
        const adminId = decodedToken.id;

        const response = await axios.get('http://localhost:3000/user/admin', {
          headers: { Authorization: `Bearer ${token}` },
        });

        const filteredUsers = response.data.filter((user) => user.id !== adminId);
        setUsers(filteredUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    const checkHealth = async()=>{
      try{
        const response = await axios.get('http://localhost:3000/health');
        setHealthStatus(response.data.status || 'Healthy')
      }catch(error){
        setHealthStatus('Unhealthy');
        console.error('Error checking health:', error)
      }
    }
  

    fetchUsers();
    checkHealth();
    
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login', { state: { fromLogout: true } });
  };

  const handleDeleteUser = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`http://localhost:3000/user/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(users.filter((user) => user.id !== userId));
      setMessage('User deleted successfully!');
      setTimeout(() => setMessage(''), 3000);
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('Failed to delete user: ' + (error.response?.data?.error || error.message));
    }
  };

  const handleUpdateUserRole = async (userId, newRole) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `http://localhost:3000/user/${userId}/role`,
        { role: newRole },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUsers(
        users.map((user) => (user.id === userId ? { ...user, role: response.data.role } : user))
      );
      setMessage('User role updated successfully!');
      setTimeout(() => setMessage(''), 3000);
    } catch (error) {
      console.error('Error updating user role:', error);
      alert('Failed to update user role: ' + (error.response?.data?.error || error.message));
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-3xl p-6 bg-white rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-center text-indigo-600 mb-6">Admin Dashboard</h1>

        {/*health status */}

        <div className='mb-4 p-2 text-center text-white bg-green-500 rounded-lg'>
           Server Health: {healthStatus}
        </div>
        

        <button
          onClick={handleLogout}
          className="mb-6 px-6 py-2 font-semibold text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
        >
          Logout
        </button>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">All Users</h2>

        {/* Display success message if available */}
        {message && (
          <div className="mb-4 p-2 text-center text-white bg-green-500 rounded-lg">
            {message}
          </div>
        )}

        <ul className="space-y-2">
          {users.map((user) => (
            <li key={user.id} className="p-4 border border-gray-200 rounded-lg bg-gray-50">
              <p className="text-lg font-medium">{user.name} {user.surname}</p>
              <p className="text-gray-600">Email: {user.email}</p>
              <p className="text-gray-500">Role: {user.role}</p>

              {/* Role Update Dropdown */}
              <div className="flex items-center space-x-2 mt-2">
                <select
                  value={user.role}
                  onChange={(e) => handleUpdateUserRole(user.id, e.target.value)}
                  className="px-3 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </select>
              </div>

              {/* Delete Button */}
              <button
                onClick={() => handleDeleteUser(user.id)}
                className="mt-2 px-4 py-2 text-sm font-semibold text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
              >
                Delete User
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdminDashboard;
