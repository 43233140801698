// src/App.js
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Register from './pages/Register';
import Login from './pages/Login';
import Home from './pages/Home'
import Blog from './pages/Blog'

import UserDashboard from './components/UserDashboard';
import AdminDashboard from './components/AdminDashboard';
import PasswordRecovery from "./pages/PasswordRecovery";
import ResetPassword from "./pages/ResetPassword";


function App() {
  const role = localStorage.getItem('role'); // Retrieve role from local storage
  return (
    
    <Router>
      
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<PasswordRecovery />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/profile"
          element={role === 'user' ? <UserDashboard /> : <Navigate to="/profile" />}
        />
        <Route
          path="/admin"
          element={role === 'admin' ? <AdminDashboard /> : <Navigate to="/admin" />}
        />
      </Routes>
    </Router>
  );
}

export default App;
