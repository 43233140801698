import React, { useState } from "react";

const PasswordRecovery = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    try {
      const response = await fetch("http://localhost:3000/password/recover", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage("Password recovery email sent. Please check your inbox.");
        setEmail(""); // Reset form
      } else {
        const result = await response.json();
        setError(result.message || "Failed to send recovery email.");
      }
    } catch (err) {
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <div style={styles.container}>
      <h2>Password Recovery</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <label style={styles.label}>Email Address</label>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          style={styles.input}
        />
        <button type="submit" style={styles.button}>Send Recovery Email</button>
      </form>
      {message && <p style={styles.success}>{message}</p>}
      {error && <p style={styles.error}>{error}</p>}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "400px",
    margin: "50px auto",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    textAlign: "center",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  form: { display: "flex", flexDirection: "column", gap: "10px" },
  label: { fontWeight: "bold", marginBottom: "5px" },
  input: {
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  button: {
    padding: "10px 15px",
    fontSize: "16px",
    color: "#fff",
    background: "#007BFF",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  success: { color: "green", marginTop: "10px" },
  error: { color: "red", marginTop: "10px" },
};

export default PasswordRecovery;
