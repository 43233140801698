import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UserDashboard = () => {
  // State variables
  const [userData, setUserData] = useState(null); // To store user data fetched from the server
  const [newEmail, setNewEmail] = useState(''); // To store new email input
  const [newSurname, setNewSurname] = useState(''); // To store new surname input
  const [updateMessage, setUpdateMessage] = useState(''); // To show update status messages
  const [isEditing, setIsEditing] = useState(false); // To toggle edit mode for user details
  const [isChangingPassword, setIsChangingPassword] = useState(false); // To toggle change password mode
  const [currentPassword, setCurrentPassword] = useState(''); // To store current password input
  const [newPassword, setNewPassword] = useState(''); // To store new password input
  const [passwordMessage, setPasswordMessage] = useState(''); // To show password change status messages
  const navigate = useNavigate(); // Hook for programmatic navigation

  // Fetch user data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve the token from local storage
        const token = localStorage.getItem('token');
        // Make an API call to fetch user profile data
        const response = await axios.get('http://localhost:3000/user/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });
        // Set user data and pre-fill edit fields
        setUserData(response.data);
        setNewEmail(response.data.email);
        setNewSurname(response.data.surname);
      } catch (error) {
        console.error('Error fetching user data:', error); // Log any errors
      }
    };
    fetchData(); // Call the fetch function
  }, []); // Empty dependency array means this runs once when the component mounts

  // Handle user logout
  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token from local storage
    navigate('/login'); // Navigate to login page
  };

  // Handle updating user details
  const handleUpdateDetails = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const token = localStorage.getItem('token'); // Get the token from local storage
      // Make an API call to update user details
      await axios.put('http://localhost:3000/user/update', 
        { email: newEmail, surname: newSurname },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setUpdateMessage('Details updated successfully!'); // Set success message
      // Update userData state to reflect changes
      setUserData((prevData) => ({
        ...prevData,
        email: newEmail,
        surname: newSurname,
      }));
      setIsEditing(false); // Exit editing mode
      // Clear the success message after 3 seconds
      setTimeout(() => setUpdateMessage(''), 3000);
    } catch (error) {
      // Set error message if update fails
      setUpdateMessage('Failed to update details: ' + (error.response?.data?.error || error.message));
    }
  };

  // Handle changing user password
  const handleChangePassword = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const token = localStorage.getItem('token'); // This should be the recovery token, not the authorization token
      // Make an API call to change the password
      await axios.post('http://localhost:3000/password/reset',
        { token, newPassword }, // Include the token in the body
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setPasswordMessage('Password changed successfully!'); // Set success message
      setTimeout(() => setPasswordMessage(''), 3000); // Clear the message after 3 seconds
      setIsChangingPassword(false); // Exit changing password mode
      setCurrentPassword(''); // Clear current password input
      setNewPassword(''); // Clear new password input
    } catch (error) {
      // Set error message if password change fails
      setPasswordMessage('Failed to change password: ' + (error.response?.data?.error || error.message));
    }
  };

  // If userData is not yet loaded, show loading message
  if (!userData) return <p className="text-center mt-8 text-lg">Loading...</p>;

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-lg p-6 bg-white rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-center text-indigo-600 mb-6">User Dashboard</h1>

        {/* Display update messages */}
        {updateMessage && (
          <div className="text-center text-green-500 font-semibold mb-4">
            {updateMessage}
          </div>
        )}

        {/* Display password change messages */}
        {passwordMessage && (
          <div className="text-center text-green-500 font-semibold mb-4">
            {passwordMessage}
          </div>
        )}

        <div className="text-center space-y-4">
          <p className="text-lg font-medium">Welcome, {userData.name} {userData.surname}!</p>
          <p className="text-gray-600">Email: {userData.email}</p>

          {/* Show edit button or form based on isEditing state */}
          {!isEditing ? (
            <button
              onClick={() => setIsEditing(true)}
              className="mt-6 px-6 py-2 font-semibold mr-2 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Edit Details
            </button>
          ) : (
            <form onSubmit={handleUpdateDetails} className="mt-6 space-y-4">
              <h2 className="text-lg font-semibold text-gray-700">Update Details</h2>

              <input
                type="email"
                placeholder="New Email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)} // Update new email state
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
              <input
                type="text"
                placeholder="New Surname"
                value={newSurname}
                onChange={(e) => setNewSurname(e.target.value)} // Update new surname state
                className="w-full  px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
              <button
                type="submit"
                className="w-full mr-2 px-4 py-2 font-semibold text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Update Details
              </button>

              {/* Cancel button to go back to the dashboard */}
              <button
                onClick={() => setIsEditing(false)}
                className="w-full px-4 py-2 font-semibold text-indigo-600 border border-indigo-600 rounded-lg hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
            </form>
          )}

          {/* Show password change section */}
          {!isChangingPassword ? (
            <button
              onClick={() => setIsChangingPassword(true)}
              className="mt-6 mr-2 px-6 py-2 font-semibold text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Change Password
            </button>
          ) : (
            <form onSubmit={handleChangePassword} className="mt-6 space-y-4">
              <h2 className="text-lg font-semibold text-gray-700">Change Password</h2>

              <input
                type="password"
                placeholder="Current Password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)} // Update current password state
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
              <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)} // Update new password state
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
              <button
                type="submit"
                className="w-full px-4 py-2 font-semibold text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Change Password
              </button>

              {/* Cancel button to go back to the dashboard */}
              <button
                onClick={() => setIsChangingPassword(false)}
                className="w-full px-4 py-2 font-semibold text-indigo-600 border border-indigo-600 rounded-lg hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
            </form>
          )}

          {/* Logout button */}
          <button
            onClick={handleLogout}
            className="mt-6 px-6 py-2 font-semibold text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
